import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { addData } from "../Servercalls";
import { imgUrl } from "../Baseurl";

function Indfame() {
  const [coursenames, setcoursenames] = useState([]);
  const [courses, setcourses] = useState([]);
  const [titles, settitles] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setcoursenames(_data?.data?.coursecategorys);
    settitles(_data?.data?.titles[0]);
    const bodydata = {
      id: _data?.data?.coursecategorys[0]._id,
    };
    const resonse2 = await addData("getcourselist", bodydata);
    var _data = resonse2;
    setcourses(_data?.data?.courseprocess[0]);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  const getfundata = async (data) => {
    const bodydata = {
      id: data._id,
    };
    const resonse = await addData("getcourselist", bodydata);
    var _data = resonse;
    setcourses(_data?.data?.courseprocess[0]);
  };

  return (
    <div>
      <Header />
      <main
        className="body"
        id="main-content"
        role="main"
        data-currency-code="INR"
      >
        <div style={{ background: "#fff" }} className="timeline-bg">
          <section className="cd-horizontal-timeline container">
            <div className="heading">
              <h2
                style={{ textTransform: "math-auto" }}
                className="text-center"
              >
                {" "}
                <a className="textbot">{titles.classWiseCourse}</a>
              </h2>

              <nav className="d-flex justify-content-center mb-5">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  {coursenames.map((data, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        getfundata(data);
                      }}
                      class="nav-link"
                      id="nav-1-tab"
                      data-toggle="tab"
                      data-target="#nav-1"
                      type="button"
                      role="tab"
                      aria-controls="nav-1"
                      aria-selected="true"
                    >
                      {data.name}
                    </button>
                  ))}
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-1"
                  role="tabpanel"
                  aria-labelledby="nav-1-tab"
                >
                  <ol>
                    <li data-date="28/02/1992">
                      <div className="row">
                        <div className="col-sm-6 col-xs-12 myhistory">
                          <div className="imgbanner">
                            <div className="img-banner firstban">
                              <div className="beffect">
                                <a>
                                  <img
                                    className="img-fluid lazyload"
                                    data-sizes="auto"
                                    style={{ width: "100%", height: "400px" }}
                                    src={imgUrl + courses.image}
                                    alt="banner"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 myhistory">
                          <div
                            // style={{ textTransform: "math-auto" }}
                            className="opl"
                            dangerouslySetInnerHTML={{
                              __html: courses.title,
                            }}
                          ></div>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>

              <div
                style={{ textDecoration: "none", textTransform: "math-auto" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: courses.description,
                  }}
                ></div>
              </div>
            </div>
          </section>
        </div>
        <hr />
      </main>
      <Footer />
    </div>
  );
}

export default Indfame;
