import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { addData } from "../Servercalls";
import { imgUrl } from "../Baseurl";
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const [contactdata, setcontactdata] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setcontactdata(_data?.data?.contactus[0]);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  const [form, setform] = useState([{ email: "" }]);
  const handleChange = (e) => {
    const myData = { ...form };
    myData[e.target.name] = e.target.value;
    setform(myData);
  };

  const addEnquary = async (e) => {
    e.preventDefault();
    const bodydata = {
      email: form.email,
    };
    try {
      const resonse = await addData("addnewsletter", bodydata);
      var _data = resonse;
      setform({ email: "" });
      toast.success(_data.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div>
      <footer className=" footer-bg foot"  style={{ background: "#f5f5f5" }}>
        <div className="container">
          <div className="row all-row">
            <div className="col-sm-12 col-12 text-left all-col subscribe-text">
              <h2 className="footer-info-heading text-center">
                Sign Up For The Newsletter
              </h2>
            </div>
            <div className="col-2" />
            <div className="col-sm-8 col-12 text-right all-col subscribe-input mt-5">
              <form
                onSubmit={(e) => {
                  addEnquary(e);
                }}
                className="form"
              >
                <fieldset className="form-fieldset">
                  {/* <input type="hidden" name="action" defaultValue="subscribe" />
                                    <input type="hidden" name="nl_first_name" defaultValue="bc" />
                                    <input type="hidden" name="check" defaultValue={1} /> */}
                  <div className="form-field">
                    {/* <label className="form-label is-srOnly" htmlFor="nl_email">Email Address</label> */}
                    <div className="form-prefixPostfix wrap">
                      <input
                        className="form-input"
                        id="nl_email"
                        name="email"
                        value={form.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="email"
                        placeholder="Your email address....."
                        aria-describedby="alertBox-message-text"
                        required
                      />
                      <input
                        className="button button--primary form-prefixPostfix-button--postfix"
                        type="submit"
                        defaultValue="Subscribe"
                      />
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div className="col-2" />
          </div>
        </div>
      </footer>
      <footer className="footer" role="contentinfo">
        <h2 className="footer-title-sr-only">Footer Start</h2>
        <div className="container">
          <div className="row">
            {/* footer brand navigate */}
            <div className="col-lg-3 col-md-3 foot-sec">
              <article
                className="footer-info-col footer-info-col--small"
                data-section-type="footer-brands"
              >
                <img className="mb-3" src={imgUrl + contactdata.logo} />
                <div className="foot-content" id="brands">
                  <span>
                    <b className="text-white ">{contactdata.title}</b>
                  </span>
                  <br />
                  <span className="text-white ">{contactdata.description}</span>
                  <ul className="socialLinks socialLinks--alt">
                    <li className="socialLinks-item">
                      <a
                        className="icon icon--twitter"
                        href={contactdata.whatsapp}
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fa fa-whatsapp" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="socialLinks-item">
                      <a
                        className="icon icon--facebook"
                        href={contactdata.linkedin}
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                      </a>
                    </li>

                    <li className="socialLinks-item">
                      <a
                        className="icon icon--instagram"
                        target="_blank"
                        href={contactdata.youtube}
                        rel="noopener"
                      >
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </article>
            </div>
            {/* footer navigation */}
            <div className="col-lg-3 col-md-3 foot-sec">
              <article
                className="footer-info-col footer-info-col--small"
                data-section-type="footer-webPages"
              >
                <h3 className="footer-info-heading">
                  Support
                  {/* <button className="toggle collapsed" data-toggle="collapse" data-target="#navigate" /> */}
                </h3>
                <div className=" foot-content" id="navigate">
                  <ul className="footer-info-list">
                    <li>
                      <Link to="/termsandconditions">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacypolicy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ’S</Link>
                    </li>
                  </ul>
                </div>
              </article>
            </div>

            {/* footer categories */}
            <div className="col-lg-3 col-md-3 foot-sec">
              <article
                className="footer-info-col footer-info-col--small"
                data-section-type="footer-categories"
              >
                <h3 className="footer-info-heading">
                  Navigate
                  {/* <button className="toggle collapsed" data-toggle="collapse" data-target="#categories" /> */}
                </h3>
                <div className=" foot-content" id="categories">
                  <ul className="footer-info-list">
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/ceonote">CEO Note</Link>
                    </li>
                    <li>
                      <Link to="/courses">Class-wise Courses</Link>
                    </li>
                    <li>
                      <Link to="/artgallery">Art Gallery</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </article>
            </div>
            {/* footer contact  */}
            <div className="col-lg-3 col-md-3 foot-sec">
              <article
                className="footer-info-col footer-info-col--small"
                data-section-type="storeInfo"
              >
                <h3 className="footer-info-heading">
                  Info
                  {/* <button className="toggle collapsed" data-toggle="collapse" data-target="#contact" /> */}
                </h3>
                <div className=" foot-content" id="contact">
                  <i className="fa fa-map-marker" />
                  <address>{contactdata.address}</address>
                  <i className="fa fa-phone" />
                  <span className="foot-phone"> {contactdata.mobile1}</span>
                  <span className="foot-phone"> {contactdata.mobile2}</span>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-copyright">
                  <p className="powered-by">
                    Powered by{" "}
                    <a
                      target="_blanck"
                      href="https://digitalraiz.com/"
                      rel="nofollow"
                    >
                      Digitalraiz
                    </a>{" "}
                    © 2024 INDFAME
                  </p>
                </div>
                <div data-content-region="ssl_site_seal--global" />
                <a
                  href="#"
                  id="scroll"
                  title="Scroll to Top"
                  style={{ display: "block" }}
                >
                  <i className="fa fa-angle-double-up" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Toaster />
    </div>
  );
};
export default Footer;
