import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { addData } from "../Servercalls";

function Faq() {
  const [faqs, setfaqs] = useState([]);
  const [titles, settitles] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setfaqs(_data?.data?.faqs);
    settitles(_data?.data?.titles[0]);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  const [show, setshow] = useState(Array(faqs.length).fill(false));
  const opencollaps = (index) => {
    const newShowStates = Array(faqs.length).fill(false);
    newShowStates[index] = !newShowStates[index];
    setshow(newShowStates);
  };

  return (
    <div>
      <Header />
      <main
        className="body"
        id="main-content"
        role="main"
        data-currency-code="INR"
      >
        <div style={{ background: "#fff" }} className="timeline-bg">
          <section className="cd-horizontal-timeline container">
            <div className="heading">
              <h2 className="text-center">
                {" "}
                <a className="textbot">{titles.faqs}</a>
              </h2>
              <div>
                <div
                  style={{ textTransform: "math-auto" }}
                  class="accordion"
                  id="accordionExample"
                >
                  {faqs.map((data, index) => (
                    <div
                      key={index}
                      class="card"
                      style={{ marginBottom: "5px" }}
                    >
                      <div class="card-header" id="heading">
                        <h2 class="mb-0">
                          <button
                            onClick={() => {
                              opencollaps(index);
                            }}
                            style={{
                              textDecoration: "none",
                              textTransform: "math-auto",
                            }}
                            class="btn btn-link text-white btn-block text-left"
                            type="button"
                            data-toggle={`#collapse${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse${index}`}
                          >
                            {data.question}
                          </button>
                        </h2>
                      </div>

                      <div
                        id={`collapse${index}`}
                        class={show[index] ? "collapse show" : "collapse"}
                        aria-labelledby="heading"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body p-4">{data.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        <hr />
      </main>
      <Footer />
    </div>
  );
}

export default Faq;
