import React from 'react'
import Header from './Header'
import Footer from './Footer'
import about from '../assets/about.png'
import staff1 from '../assets/staff1.png'
import staff2 from '../assets/staff2.png'
import Slider from "react-slick";

import ad1 from '../assets/staff/tharun.jpg'
import ad2 from '../assets/staff/ias.jpg'
import ad3 from '../assets/staff/geetha.jpg'
import ad4 from '../assets/staff/img4.jpg'

function About() {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Header />
            <main className="body" id="main-content" role="main" data-currency-code="INR">
                <div style={{ background: "#fff" }} className="">
                    <section className="cd-horizontal-timeline container">
                        <div className="heading text-center">

                            <h2> <a className="textbot">Advisory </a></h2>
                        </div>
                        <div className="row">
                            <div className='col-md-6'>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={ad2} style={{ width: "100%", height: "300px" }} />
                                        <h5>Biyyala Venkat Papa Rao</h5>
                                        <span> Indian filmmaker, and a former member of the Indian Administrative Service (IAS). </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={ad1} style={{ width: "100%", height: "300px" }} />
                                        <h5>Tharun Bhascker Dhaassyam </h5>
                                        <span>Vice President</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                     
                    </section>
                </div>
                <div style={{ background: "#fff" }} className="mt-5">
                    <section className="cd-horizontal-timeline container">
                        <div className="heading text-center">

                            <h2> <a className="textbot">Governing  </a></h2>
                        </div>
                        <div className="row">
                            <div className='col-md-6'>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={ad3} style={{ width: "100%", height: "300px" }} />
                                        <h5>Geetha Bhascker Dhaassyam</h5>
                                        <span>President</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={ad4} style={{ width: "100%", height: "300px" }} />
                                        <h5>Durga Prasad Sandaka</h5>
                                        <span>General Secretary</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-4">
                                <div className="pagination">
                                    <div>
                                        <a className="current">01</a>
                                    </div>
                                    <div className="total">02</div>
                                </div>

                            </div>
                            <div className="col-4">
                                <div className="text-center mt-4">
                                    <a href="#Meet"><b>Scroll</b></a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div id='Meet' className="timeline-bg">
                    <section className="cd-horizontal-timeline container mb-5">
                        <div className="heading text-center">
                            <h2> <a className="textbot">Meet Our Staff</a></h2>

                        </div>
                        <div>
                            <Slider {...settings}>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={staff1} style={{ width: "100%", height: "200px" }} />
                                        <h5>D.swamy</h5>
                                        <span>A Visual Artist, Skills Trainer</span>
                                    </div>
                                </div>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={staff2} style={{ width: "100%", height: "200px" }} />
                                        <h5>D.swamy</h5>
                                        <span>A Visual Artist, Skills Trainer</span>
                                    </div>
                                </div>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={staff1} style={{ width: "100%", height: "200px" }} />
                                        <h5>D.swamy</h5>
                                        <span>A Visual Artist, Skills Trainer</span>
                                    </div>
                                </div>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={staff2} style={{ width: "100%", height: "200px" }} />
                                        <h5>D.swamy</h5>
                                        <span>A Visual Artist, Skills Trainer</span>
                                    </div>
                                </div>
                                <div style={{ background: "#fff" }} className='card rounded cardshadow text-center m-3'>
                                    <div className='p-3'>
                                        <img src={staff1} style={{ width: "100%", height: "200px" }} />
                                        <h5>D.swamy</h5>
                                        <span>A Visual Artist, Skills Trainer</span>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="pagination">
                                    <div>
                                        <a className="current">02</a>
                                    </div>
                                    <div className="total">02</div>
                                </div>

                            </div>
                            <div className="col-4">
                                <div className="text-center mt-4">
                                    {/* <a ><b>Next</b></a> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default About