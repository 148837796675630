import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Cssfiles/Style.css'
import './Cssfiles/Style2.css'
import './Cssfiles/Style3.css'
import './Cssfiles/Style4.css'
import './Cssfiles/Style5.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './Components/Home'
import About from './Components/About';
import Ceonote from './Components/Ceonote';
import Artgallery from './Components/Artgallery';
import Indfame from './Components/Indfame';
import Contactus from './Components/Contactus';
import Advisory from './Components/Advisory';
import Faq from './Components/Faq';
import Termsandconditions from './Components/Termsandconditions';
import Privacypolicy from './Components/Privacypolicy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ceonote" element={<Ceonote />} />
          <Route path="/artgallery" element={<Artgallery />} />
          <Route path="/courses" element={<Indfame />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/advisory" element={<Advisory />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
