import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import youtubeicon from "../assets/youtubeicon.png";
import whatsapp from "../assets/whatsapp.png";
import linkedin from "../assets/linkedin.png";
import { addData } from "../Servercalls";
import toast, { Toaster } from "react-hot-toast";
import { imgUrl } from "../Baseurl";

const Header = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  };
  const [form, setform] = useState([
    {
      name: "",
      email: "",
      subject: "",
      message: "",
      school: "",
      designation: "",
    },
  ]);
  const handleChange = (e) => {
    const myData = { ...form };
    myData[e.target.name] = e.target.value;
    setform(myData);
  };

  const closeModal = () => {
    const modalElement = document.getElementById("vedioModal1");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  const addEnquary = async (e) => {
    e.preventDefault();
    const bodydata = {
      name: form.name,
      email: form.email,
      subject: form.subject,
      message: form.message,
      school: form.school,
      designation: form.designation,
    };
    try {
      const resonse = await addData("enquiry/addenquiry", bodydata);
      var _data = resonse;
      closeModal();
      setform({
        name: "",
        email: "",
        subject: "",
        message: "",
        school: "",
        designation: "",
      });

      toast.success(_data.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const [contactdata, setcontactdata] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setcontactdata(_data?.data?.contactus[0]);
  };

  useEffect(() => {
    scrollToTop();
    getAllbanners();
  }, []);
  return (
    <div>
      <header
        className={show == true ? "header is-open" : "header"}
        role="banner"
      >
        {/* first header */}

        {/* second header */}
        <a
          onClick={() => {
            setShow(!show);
          }}
          className={
            show == true ? "mobileMenu-toggle is-open" : "mobileMenu-toggle"
          }
          data-mobile-menu-toggle="menu"
        >
          <span className="mobileMenu-toggleIcon">Toggle menu</span>
        </a>
        <div className="mainhead">
          <div className="top-header container-fluid">
            <div className="row">
              <div
                className="col-lg-3 col-md-2 col-sm-3 col-xs-3 text-left d-flex"
                id="logo"
              >
                <h1 className="header-logo header-logo--left">
                  <Link
                    to="/"
                    className="header-logo__link"
                    data-header-logo-link
                  >
                    <div className="header-logo-image-container">
                      <img
                        className="header-logo-image logopadding"
                        src={imgUrl + contactdata.logo}
                        alt="INDFAME"
                        title="INDFAME"
                      />
                    </div>
                  </Link>
                </h1>
              </div>
              <div className="col-lg-9 col-md-7 col-sm-8 head-nav">
                <div
                  className={
                    show == true
                      ? "navPages-container is-open"
                      : "navPages-container"
                  }
                  id="menu"
                  data-menu
                >
                  <nav className="navPages">
                    <ul className="navPages-list">
                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/"
                          aria-label="Home"
                        >
                          Home
                        </Link>
                      </li>

                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/about"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/about"
                          aria-label="About"
                        >
                          About us
                        </Link>
                      </li>
                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/ceonote"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/ceonote"
                          aria-label="CEO"
                        >
                          CEO Note
                        </Link>
                      </li>
                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/courses"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/courses"
                          aria-label="INDFAME"
                        >
                          Grade-wise Courses
                        </Link>
                      </li>
                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/artgallery"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/artgallery"
                          aria-label="Art"
                        >
                          Art Gallery
                        </Link>
                      </li>
                      {/* <li className="navPages-item">
                                                <Link className={`nav-link ${location.pathname === "/advisory" ? "navPages-action activePage" : "navPages-action"}`} to="/advisory" aria-label="Contact">
                                                    Advisory
                                                </Link>
                                            </li> */}
                      <li className="navPages-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === "/contactus"
                              ? "navPages-action activePage"
                              : "navPages-action"
                          }`}
                          to="/contactus"
                          aria-label="Contact"
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="hidenavcol" />
            </div>
          </div>
        </div>
        <div >
          <div className="menu-container" id="jspd">
            <div className="collapse-icon">
              <span className="social_media_text" />
            </div>
            <div className="menu-item">
              <a
                target="_blank"
                href={contactdata.youtube}
                style={{ padding: "0px" }}
              >
                <img style={{ width: "100%" }} src={youtubeicon} alt="" />
              </a>
            </div>
            <div className="menu-item">
              <a
                target="_blank"
                href={contactdata.whatsapp}
                style={{ padding: "2px" }}
              >
                <img style={{ width: "100%" }} src={whatsapp} alt="" />
              </a>
            </div>
            <div className="menu-item mb-2">
              <a
                target="_blank"
                href={contactdata.linkedin}
                style={{ padding: "0px" }}
              >
                <img
                  style={{ width: "100%", height: "21px" }}
                  src={linkedin}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <div>
          <div className="conteststy1">
            <a
              data-toggle="modal"
              data-target="#vedioModal1"
              type="button"
              style={{ background: "#628457e8", color: "#fff" }}
              className="btn btndirection p-2 "
            >
              <i class="fa fa-phone iconsty2" aria-hidden="true"></i> Enquiry{" "}
            </a>
          </div>
        </div>

        <div id="vedioModal1" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="row pl-4 pr-4 pt-4">
                <div className="col-6">
                  <span style={{ fontSize: "18px" }}>
                    <b>Enquiry</b>
                  </span>
                </div>
                <div className="col-6">
                  <button type="button" className="close" data-dismiss="modal">
                    ×
                  </button>
                </div>
              </div>
              <hr style={{ margin: "10px" }} />
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    addEnquary(e);
                  }}
                >
                  <input
                    className="form-input mb-3"
                    name="name"
                    value={form.name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    placeholder="Enter Your Name"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <input
                    className="form-input mb-3"
                    name="email"
                    value={form.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="email"
                    placeholder="Enter Your Email"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <input
                    className="form-input mb-3"
                    name="school"
                    value={form.school}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    placeholder="Enter Your School | College"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <input
                    className="form-input mb-3"
                    name="designation"
                    value={form.designation}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    placeholder="Enter Your Designation"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <input
                    className="form-input mb-3"
                    name="subject"
                    value={form.subject}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    placeholder="Enter Your Subject"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <textarea
                    rows={3}
                    className="form-input mb-3"
                    value={form.message}
                    id="nl_email"
                    name="message"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    placeholder="Enter Your Message"
                    aria-describedby="alertBox-message-text"
                    required
                  />
                  <div style={{ float: "right" }}>
                    <input
                      className="button button--primary form-prefixPostfix-button--postfix"
                      type="submit"
                      defaultValue="Submit"
                    />
                    <input
                      style={{ background: "#ff0000b0", color: "#fff" }}
                      className="button form-prefixPostfix-button--postfix"
                      data-dismiss="modal"
                      type="button"
                      defaultValue="Cancel"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Toaster />
    </div>
  );
};
export default Header;
