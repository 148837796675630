import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReactPaginate from "react-paginate";
import { imgUrl } from "../Baseurl";
import { addData } from "../Servercalls";

function Artgallery() {
  const [arts, setarts] = useState([]);
  const [news, setnews] = useState([]);

  const [Videos, setVideos] = useState([]);

  const [listPerPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  const [listPerPage1] = useState(1);
  const [pageNumber1, setPageNumber1] = useState(0);

  const [listPerPage2] = useState(6);
  const [pageNumber2, setPageNumber2] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = arts.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(arts.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const pagesVisited1 = pageNumber1 * listPerPage1;
  const lists1 = news.slice(pagesVisited1, pagesVisited1 + listPerPage1);
  const pageCount1 = Math.ceil(news.length / listPerPage1);
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected);
  };

  const pagesVisited2 = pageNumber2 * listPerPage2;
  const lists2 = Videos.slice(pagesVisited2, pagesVisited2 + listPerPage2);
  const pageCount2 = Math.ceil(Videos.length / listPerPage2);
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected);
  };

  const getAllbanners = async () => {
    const bodydata = {
      type: "arts",
    };
    const resonse = await addData("getallactivegallerys", bodydata);
    var _data = resonse;
    setarts(_data?.data?.gallerys);
  };
  const getAllbanners1 = async () => {
    const bodydata = {
      type: "news",
    };
    const resonse = await addData("getallactivegallerys", bodydata);
    var _data = resonse;
    setnews(_data?.data?.gallerys);
  };

  const [titles, settitles] = useState([]);
  const getAllbanners123 = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    settitles(_data?.data?.titles[0]);
    setVideos(_data?.data?.videos);
  };

  useEffect(() => {
    getAllbanners();
    getAllbanners1();
    getAllbanners123();
  }, []);

  return (
    <div>
      <Header />
      <main
        className="body"
        id="main-content"
        role="main"
        data-currency-code="INR"
      >
        <div style={{ background: "#fff" }} className="">
          <section className="container">
            <div className="heading text-center">
              <h2 className="mt-5 pt-3">
                {" "}
                <a className="textbot">{titles.artGallery}</a>
              </h2>
            </div>

            {lists.map((data, key) => (
              <div key={key} className="mt-4">
                <div className="row mb-3">
                  {data?.object1 == undefined ? (
                    ""
                  ) : (
                    <div className="col-md-6 p-2">
                      <div className="text-center">
                        <img
                          className="rounded"
                          style={{ width: "100%", height: "425px" }}
                          src={imgUrl + data?.object1?.image}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="row">
                      {data?.object2 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6  p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object2?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object3 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6  p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object3?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {data?.object4 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object4?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object5 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object5?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      {data?.object6 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object6?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object7 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object7?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row ">
                      {data?.object8 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object8?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object9 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object9?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {data?.object10 == undefined ? (
                    ""
                  ) : (
                    <div className="col-md-6 p-2">
                      <div className="text-center">
                        <img
                          className="rounded"
                          style={{ width: "100%", height: "425px" }}
                          src={imgUrl + data?.object10?.image}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-3" style={{ float: "right" }}>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                total={lists.length}
              />
            </div>
          </section>

          <section className="container">
            <div style={{ marginTop: "100px" }} className="heading text-center">
              <h2 className="">
                {" "}
                <a className="textbot">{titles.news}</a>
              </h2>
            </div>

            {lists1.map((data, key) => (
              <div key={key} className="mt-4">
                <div className="row mb-3">
                  {data?.object1 == undefined ? (
                    ""
                  ) : (
                    <div className="col-md-6 p-2">
                      <div className="text-center">
                        <img
                          className="rounded"
                          style={{ width: "100%", height: "425px" }}
                          src={imgUrl + data?.object1?.image}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="row">
                      {data?.object2 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6  p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object2?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object3 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6  p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object3?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {data?.object4 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object4?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object5 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object5?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="row">
                      {data?.object6 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object6?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object7 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object7?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row ">
                      {data?.object8 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object8?.image}
                            />
                          </div>
                        </div>
                      )}
                      {data?.object9 == undefined ? (
                        ""
                      ) : (
                        <div className="col-lg-6 p-2">
                          <div className="text-center">
                            <img
                              className="rounded"
                              style={{ width: "100%", height: "205px" }}
                              src={imgUrl + data?.object9?.image}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {data?.object10 == undefined ? (
                    ""
                  ) : (
                    <div className="col-md-6 p-2">
                      <div className="text-center">
                        <img
                          className="rounded"
                          style={{ width: "100%", height: "425px" }}
                          src={imgUrl + data?.object10?.image}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="mt-3" style={{ float: "right" }}>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount1}
                onPageChange={changePage1}
                containerClassName={"pagination"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                total={lists1.length}
              />
            </div>
          </section>

          <section className="container">
            <div style={{ marginTop: "100px" }} className="heading text-center">
              <h2 className="">
                {" "}
                <a className="textbot mb-3">Videos Of INDFAME</a>
              </h2>
            </div>

            <div className="row mb-3">
              {lists2.map((data, key) => (
                <div className="col-md-4 mt-5" key={key}>
                  <iframe
                    width="95%"
                    height="250px"
                    src={data.link}
                    title="YouTube video player"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
            </div>

            <div className="mt-3" style={{ float: "right" }}>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount2}
                onPageChange={changePage2}
                containerClassName={"pagination"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                total={lists2.length}
              />
            </div>
          </section>
        </div>
        <hr />
      </main>
      <Footer />
    </div>
  );
}

export default Artgallery;
