import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import about from "../assets/about.png";
// import staff1 from '../assets/staff1.png'
// import staff2 from '../assets/staff2.png'
import Slider from "react-slick";

import ad1 from "../assets/staff/tharun.jpg";
import ad2 from "../assets/staff/ias.jpg";
import ad3 from "../assets/staff/geetha.jpg";
import ad4 from "../assets/staff/img4.jpg";

import staff1 from "../assets/staff/staff1.jpg";
import staff2 from "../assets/staff/staff2.jpg";
import staff3 from "../assets/staff/staff3.jpg";
import staff4 from "../assets/staff/staff4.jpg";
import staff5 from "../assets/staff/staff5.jpg";
import staff6 from "../assets/staff/staff6.jpg";
import staff7 from "../assets/staff/staff7.jpg";
import staff8 from "../assets/staff/staff8.jpg";
import staff9 from "../assets/staff/staff9.jpg";
import { addData } from "../Servercalls";
import { imgUrl } from "../Baseurl";
import Av1 from "../assets/av1.jpeg";
import Av2 from "../assets/av2.jpeg";

function About() {
  var settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [aboutus, setaboutus] = useState([]);
  const [advisorys, setadvisorys] = useState([]);
  const [staffs, setstaffs] = useState([]);
  const [titles, settitles] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setaboutus(_data?.data?.aboutus[0]);
    settitles(_data?.data?.titles[0]);
    setadvisorys(_data?.data?.advisorys);
    setstaffs(_data?.data?.staffs);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  return (
    <div>
      <Header />
      <main
        className="body"
        id="main-content"
        role="main"
        data-currency-code="INR"
      >
        <div style={{ background: "#fff" }} className="timeline-bg">
          <section className="cd-horizontal-timeline container">
            <div className="heading text-center">
              <h2>
                {" "}
                <a className="textbot">{titles.aboutus}</a>
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-center">
                  <img className="aboutimg" src={imgUrl + aboutus.image} />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutus.title,
                  }}
                ></div>
              </div>
              <div className="col-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutus.description,
                  }}
                ></div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="pagination">
                  <div>
                    <a className="current">01</a>
                  </div>
                  <div className="total">04</div>
                </div>
              </div>
              <div className="col-4">
                <div className="text-center mt-4">
                  <a href="#Govts">
                    <b>Scroll</b>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div style={{ background: "#f5f5f5" }} id="Govts">
          <section className="container">
            <div className="row mb-4">
              <div className="col-md-12 pb-2 pt-2">
                <div className="heading text-center">
                  <h2 className="pb-2">
                    <a className="textbot">
                      {" "}
                      Where Art is taken beyond boundaries
                    </a>{" "}
                    <small style={{ fontWeight: "bold" }}>
                      The Art of Avakaya
                    </small>
                  </h2>
                </div>
                <p className="opl pt-3 text-center">
                  The pickle-making process, like visual art, involves a
                  meticulous blend of ingredients and techniques, reflecting
                  cultural heritage and craftsmanship. At Indfame, we believe
                  art is a lifestyle, celebrating the rich traditions and
                  communal rituals that infuse everyday practices with
                  creativity and meaning.
                </p>{" "}
              </div>

              <div className="col-md-6">
                <div className="text-center ">
                  <img
                    src={Av1}
                    style={{
                      height: "430px",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center ">
                  <img src={Av2} style={{ height: "430px", width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="pagination">
                  <div>
                    <a className="current">02</a>
                  </div>
                  <div className="total">04</div>
                </div>
              </div>
              <div className="col-4">
                <div className="text-center mt-4">
                  <a href="#Advisory">
                    <b>Next</b>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="Advisory" style={{ background: "#ffffff" }}>
          <div className="">
            <section className="cd-horizontal-timeline container">
              <div className="heading text-center">
                <h2 className="pt-4">
                  {" "}
                  <a className="textbot ">{titles.advisory}</a>
                </h2>
              </div>
              <div className="row mt-5 pt-2">
                {advisorys.map((data, index) => (
                  <div key={index} className="col-md-3">
                    <div
                      style={{ background: "#fff" }}
                      className="carheight card rounded cardshadow text-center"
                    >
                      <img
                        src={imgUrl + data.image}
                        className="aboutimagesize"
                      />
                      <div className="p-2">
                        <span style={{ fontSize: "18px" }}>
                          <b>{data.name}</b>
                        </span>
                        <br />
                        <p className="mt-2">{data.designation} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row">
                <div className="col-4">
                  <div className="pagination">
                    <div>
                      <a className="current">03</a>
                    </div>
                    <div className="total">04</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="text-center mt-4">
                    <a href="#staff">
                      <b>Next</b>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div id="staff" style={{ background: "#fff" }} className="timeline-bg">
          <section className="cd-horizontal-timeline container mb-5">
            <div className="heading text-center">
              <h2>
                {" "}
                <a className="textbot">{titles.crew}</a>
              </h2>
            </div>
            <div>
              <Slider {...settings}>
                {staffs.map((data, index) => (
                  <div
                    key={index}
                    style={{ background: "#fff" }}
                    className="card rounded cardshadow text-center m-3 p-3"
                  >
                    <div>
                      <img
                        src={imgUrl + data.image}
                        style={{ width: "100%", height: "250px" }}
                      />
                      <div className="pt-3">
                        <span style={{ fontSize: "18px" }}>
                          <b>{data.name}</b>
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="pagination">
                  <div>
                    <a className="current">04</a>
                  </div>
                  <div className="total">04</div>
                </div>
              </div>
              <div className="col-4">
                <div className="text-center mt-4"></div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default About;
