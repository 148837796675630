import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { addData } from "../Servercalls";

function Contactus() {

    const [contactdata, setcontactdata] = useState([])
    const [titles, settitles] = useState([])
    const getAllbanners = async () => {
        const resonse = await addData("getallmodules", {})
        var _data = resonse
        setcontactdata(_data?.data?.settings[0])
        settitles(_data?.data?.titles[0])
    }

    useEffect(() => {
        getAllbanners()
    }, [])

    return (
        <div>
            <Header />
            <main className="body" id="main-content" role="main" data-currency-code="INR">
                <div style={{ background: "#fff" }} className="">
                    <section className="container">
                        <div className="heading text-center">

                            <h2 className='mt-5 pt-3'> <a className="textbot ">{titles.privacyPolicy}</a></h2>
                        </div>
                        <div className="row mt-5">
                            <div className='col-12'>
                            <div
                                    dangerouslySetInnerHTML={{
                                        __html: contactdata.privacyPolicy,
                                    }}
                                ></div>
                            </div>
                        </div>
                        
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Contactus