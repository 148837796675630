import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import { addData } from "../Servercalls";
import { imgUrl } from "../Baseurl";

function About() {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [ceonote, setceonote] = useState([]);
  const [titles, settitles] = useState([]);
  const [feasts, setfeasts] = useState([]);
  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setceonote(_data?.data?.ceonotes[0]);
    settitles(_data?.data?.titles[0]);
    setfeasts(_data?.data?.feasts);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  return (
    <div>
      <Header />
      <main
        className="body"
        id="main-content"
        role="main"
        data-currency-code="INR"
      >
        <div style={{ background: "#fff" }} className="timeline-bg">
          <section className="cd-horizontal-timeline container">
            <div className="heading text-center">
              <h2>
                {" "}
                <a className="textbot">{titles.ceonote}</a>
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-center">
                  <img  src={imgUrl + ceonote.image} id="oppd" />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: ceonote.title,
                  }}
                ></div>
              </div>
              <div className="col-md-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: ceonote.description,
                  }}
                ></div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-4">
                <div className="pagination">
                  <div>
                    <a className="current">01</a>
                  </div>
                  <div className="total">02</div>
                </div>
              </div>
              <div className="col-4">
                <div className="text-center mt-4">
                  <a href="#Arts">
                    <b>Scroll</b>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <div id="Arts" className="timeline-bg">
            <section className="cd-horizontal-timeline container">
              <div className="heading text-center">
                <h2>
                  {" "}
                  <a className="textbot">{titles.feast}</a>
                </h2>
              </div>
              <div>
                <Slider {...settings}>
                  {feasts.map((data, key) => (
                    <div key={key} className="">
                      <div style={{ background: "#f5f5f5" }} className="m-3">
                        <img
                          className="rounded"
                          src={imgUrl + data.image}
                          style={{ width: "100%", height: "300px" }}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="pagination">
                    <div>
                      <a className="current">02</a>
                    </div>
                    <div className="total">02</div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="text-center mt-4"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default About;
