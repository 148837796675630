import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import ceo from '../assets/ceo.png'
import { addData } from "../Servercalls";
import toast, { Toaster } from 'react-hot-toast';

function Contactus() {
    const [form, setform] = useState([{ name: "", email: "", subject: "", message: "", school: "", designation: "" }])
    const handleChange = (e) => {
        const myData = { ...form }
        myData[e.target.name] = e.target.value;
        setform(myData)
    }
    const addEnquary = async (e) => {
        e.preventDefault()
        const bodydata = {
            name: form.name,
            email: form.email,
            subject: form.subject,
            message: form.message,
            school: form.school,
            designation: form.designation,
        }
        try {
            const resonse = await addData("enquiry/addenquiry", bodydata)
            var _data = resonse
            setform({ name: "", email: "", subject: "", message: "", school: "", designation: "" })

            toast.success(_data.data.message)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const [contactdata, setcontactdata] = useState([])
    const [titles, settitles] = useState([])
    const getAllbanners = async () => {
        const resonse = await addData("getallmodules", {})
        var _data = resonse
        setcontactdata(_data?.data?.contactus[0])
        settitles(_data?.data?.titles[0])
    }

    useEffect(() => {
        getAllbanners()
    }, [])

    return (
        <div>
            <Header />
            <main className="body" id="main-content" role="main" data-currency-code="INR">
                <div style={{ background: "#fff" }} className="">
                    <section className="container">
                        <div className="heading text-center">

                            <h2 className='mt-5 pt-3'> <a className="textbot ">{titles.contactus}</a></h2>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6 col-12">
                                <h4 className='mb-4'><b>Location & details</b></h4>
                                <h5><b>{contactdata.title}</b></h5>
                                <p ><b>(INFAME SOCIETY(Refd No 892 of 2023))</b></p>
                                {/* <p>The session begins when the user starts typing a query, and concludes when they select a place and a call to Place Details is made </p> */}
                                <div className="foot-content" >
                                    <div style={{ fontSize: "16px" }}>
                                        <i className="fa fa-map-marker mr-2" />
                                        <span>
                                        {contactdata.address}
                                         </span>
                                    </div>
                                    <br />
                                    <div style={{ fontSize: "16px" }}>
                                        <i class="fa fa-envelope-o mr-2" aria-hidden="true"></i>
                                        <span> {contactdata.email1}</span>, <span>  {contactdata.email2}</span>
                                    </div>
                                    <br />
                                    <div style={{ fontSize: "16px" }}>
                                        <i className="fa fa-phone mr-2" />
                                        <span >{contactdata.mobile1}</span>, <span > {contactdata.mobile2}</span>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <h4 className='mb-4'><b>Send Us a Message</b></h4>
                                <form onSubmit={(e) => { addEnquary(e) }}>
                                    <input className="form-input mb-3" name="name" value={form.name} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Name" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="email" value={form.email} onChange={(e) => { handleChange(e) }} type="email" placeholder="Enter Your Email" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="school" value={form.school} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your School | College" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="designation" value={form.designation} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Designation" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="subject" value={form.subject} onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Subject" aria-describedby="alertBox-message-text" required />
                                    <textarea rows={3} className="form-input mb-3" value={form.message} id="nl_email" name="message" onChange={(e) => { handleChange(e) }} type="text" placeholder="Enter Your Message" aria-describedby="alertBox-message-text" required />
                                    <div style={{ float: "right" }}>
                                        <input className="button button--primary form-prefixPostfix-button--postfix" type="submit" defaultValue="Submit" />
                                    </div>
                                </form>

                                {/* <form>
                                   <input className="form-input mb-3" name="nl_email" type="text" placeholder="Enter Your Name" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="nl_email" type="email" placeholder="Enter Your Email" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="nl_email" type="text" placeholder="Enter Your School | College" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="nl_email" type="text" placeholder="Enter Your Designation" aria-describedby="alertBox-message-text" required />
                                    <input className="form-input mb-3" name="nl_email" type="text" placeholder="Enter Your Subjact" aria-describedby="alertBox-message-text" required />
                                    <textarea rows={3} className="form-input mb-3" id="nl_email" name="nl_email" type="text" placeholder="Enter Your Message" aria-describedby="alertBox-message-text" required />
                                    <input className="button button--primary form-prefixPostfix-button--postfix" type="submit" defaultValue="Subscribe" />

                                </form> */}
                            </div>
                        </div>
                        {/* <div className="row mt-5">
                            <div className='col-12'>
                                <div className="mapouter"><div className="gmap_canvas"><iframe src="https://maps.google.com/maps?q=Sai Lakshmi Nilayam, 1-1-114, Rathnanagar Colony, Kothapet, Hyderabad, Telangana – 500035&t=&z=10&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" style={{ width: '100%', height: '400px' }} /><style dangerouslySetInnerHTML={{ __html: ".mapouter{position:relative;height:400px;width:100%;background:#fff;} .maprouter a{color:#fff !important;position:absolute !important;top:0 !important;z-index:0 !important;}" }} /><a href="https://blooketjoin.org/blooket-login/">blooket login</a><style dangerouslySetInnerHTML={{ __html: ".gmap_canvas{overflow:hidden;height:400px;width:100%}.gmap_canvas iframe{position:relative;z-index:2}" }} /></div></div>
                            </div>
                        </div> */}
                    </section>
                </div>
            </main>
            <Toaster />
            <Footer />
        </div>
    )
}

export default Contactus