import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import image1 from "../assets/a1.jpg";
import image2 from "../assets/a2.jpg";
import loader from "../assets/loaders.gif";
import { addData } from "../Servercalls";
import { imgUrl } from "../Baseurl";
import Av1 from "../assets/av1.jpeg";
import Av2 from "../assets/av2.jpeg";

const Home = () => {
  const [showloader, setshowloader] = useState(true);
  const [banners, setBanners] = useState([]);
  const [aboutus, setaboutus] = useState([]);
  const [ceonote, setceonote] = useState([]);
  const [schools, setschools] = useState([]);
  const [testimonials, settestimonials] = useState([]);
  const [coursenames, setcoursenames] = useState([]);
  const [courses, setcourses] = useState([]);
  const [titles, settitles] = useState([]);
  const [govtt, setgovtt] = useState([]);

  const [ArtGallery, setArtGallery] = useState([]);

  const getAllbanners = async () => {
    const resonse = await addData("getallmodules", {});
    var _data = resonse;
    setBanners(_data?.data?.sliders);
    setaboutus(_data?.data?.aboutus[0]);
    setceonote(_data?.data?.ceonotes[0]);
    settitles(_data?.data?.titles[0]);
    setgovtt(_data?.data?.government[0]);
    setschools(_data?.data?.schools);
    settestimonials(_data?.data?.testimonis);
    setcoursenames(_data?.data?.coursecategorys);
    setArtGallery(_data?.data?.arts);
    const bodydata = {
      id: _data?.data?.coursecategorys[0]._id,
    };
    const resonse2 = await addData("getcourselist", bodydata);
    var _data = resonse2;
    setcourses(_data?.data?.courseprocess[0]);
    setshowloader(false);
  };

  useEffect(() => {
    getAllbanners();
  }, []);

  const getfundata = async (data) => {
    const bodydata = {
      id: data._id,
    };
    const resonse = await addData("getcourselist", bodydata);
    var _data = resonse;
    setcourses(_data?.data?.courseprocess[0]);
  };

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {showloader == true ? (
        <div className="text-center mt-5 pt-5">
          <img src={loader} width="300" height="200" />
        </div>
      ) : (
        <>
          <Header />

          <div data-content-region="header_bottom--global" />
          <div data-content-region="header_bottom" />
          <main
            className="body"
            id="main-content"
            role="main"
            data-currency-code="INR"
          >
            <div>
              <div className="main full">
                <div className="container section-space">
                  <div data-content-region="home_below_menu" />
                  <section className="heroCarousel">
                    <Slider {...settings}>
                      {banners.map((data, index) => (
                        <div key={index}>
                          <div className="row heroCarousel-slide  heroCarousel-slide--first">
                            <div className="col-md-5 col-12 order-md-1 order-2">
                              <div className="heroCarousel-content">
                                <p className="heroCarousel-title">
                                  {data.title}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-7 col-12 order-md-2 order-1">
                              <a
                                href="#"
                                className="heroCarousel-image-wrapper d-flex justify-content-center"
                              >
                                <img
                                  src={imgUrl + data.image}
                                  alt=""
                                  title
                                  data-sizes="auto"
                                  className=" heroCarousel-image "
                                />
                              </a>
                            </div>
                            <a
                              href="#"
                              className="heroCarousel-image-wrapper"
                            ></a>
                          </div>
                        </div>
                      ))}
                    </Slider>

                    <span
                      data-carousel-content-change-message
                      className="aria-description--hidden"
                      aria-live="polite"
                      role="status"
                    />
                    <div className="row mt-3">
                      <div className="col-4">
                        <div className="pagination">
                          <div>
                            <a className="current">01</a>
                          </div>
                          <div className="total">09</div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="text-center mt-4">
                          <a href="#about">
                            <b>Scroll</b>
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div data-content-region="home_below_carousel" />
              </div>

              <div className="timeline-bg" id="about">
                <section className="cd-horizontal-timeline container">
                  <div className="heading text-center">
                    <h2>
                      <a className="textbot">{titles.aboutus}</a>
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-center">
                        <img
                          className="aboutimg"
                          src={imgUrl + aboutus.image}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="opl"
                        dangerouslySetInnerHTML={{
                          __html: aboutus.title,
                        }}
                      ></div>
                      <Link
                        to="/about"
                        aria-label="Slide number 2, Shop now"
                        className="heroCarousel-action button button--primary button--large"
                      >
                        View more
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">02</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#ceo">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div
                id="ceo"
                style={{ background: "#fff" }}
                className="timeline-bg"
              >
                <section className="cd-horizontal-timeline container">
                  <div className="heading text-center">
                    <h2>
                      <a className="textbot">{titles.ceonote}</a>
                    </h2>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12 order-md-1 order-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: ceonote.title,
                        }}
                      ></div>

                      <Link
                        to="ceonote"
                        aria-label="Slide number 2, Shop now"
                        className="heroCarousel-action button button--primary button--large"
                      >
                        View more
                      </Link>
                    </div>
                    <div className="col-md-6 col-12 order-md-2 order-1 mt-5">
                      <div className="text-center">
                        <img
                          className="seoimgsize"
                          src={imgUrl + ceonote.image}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">03</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#INDFAME">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div id="INDFAME" className="timeline-bg">
                <section className="cd-horizontal-timeline container">
                  <div className="heading">
                    <h2
                      style={{ textTransform: "math-auto" }}
                      className="text-center"
                    >
                      {" "}
                      <a className="textbot">{titles.classWiseCourse}</a>
                    </h2>

                    <nav className="d-flex justify-content-center mb-5">
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        {coursenames.map((data, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              getfundata(data);
                            }}
                            class="nav-link "
                            id="nav-1-tab"
                            data-toggle="tab"
                            data-target="#nav-1"
                            type="button"
                            role="tab"
                            aria-controls="nav-1"
                            aria-selected="true"
                          >
                            {data?.name}
                          </button>
                        ))}
                      </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-1"
                        role="tabpanel"
                        aria-labelledby="nav-1-tab"
                      >
                        <ol>
                          <li data-date="28/02/1992">
                            <div className="row">
                              <div className="col-sm-6 col-xs-12 myhistory">
                                <div className="imgbanner">
                                  <div className="img-banner firstban">
                                    <div className="beffect">
                                      <a>
                                        <img
                                          className="img-fluid lazyload"
                                          data-sizes="auto"
                                          style={{
                                            width: "100%",
                                            height: "400px",
                                          }}
                                          src={imgUrl + courses?.image}
                                          alt="banner"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-xs-12 myhistory">
                                <div
                                  // style={{ textTransform: "math-auto" }}
                                  className="opl"
                                  dangerouslySetInnerHTML={{
                                    __html: courses?.title,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">04</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#Art">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div
                id="Art"
                style={{ background: "#fff" }}
                className="videobann section-space"
              >
                <div className="container">
                  <div className="heading text-center  mb-5">
                    <h2>
                      {" "}
                      <a className="textbot">{titles.artGallery}</a>
                    </h2>
                  </div>

                  <div>
                    <Slider {...settings3}>
                      {ArtGallery.map((data, key) => (
                        <div key={key} className="">
                          <div
                            style={{ background: "#f5f5f5" }}
                            className="m-3"
                          >
                            <img
                              className="rounded"
                              src={imgUrl + data.image}
                              style={{ width: "100%", height: "400px" }}
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>

                  {/* <div className="col-sm-5 col-12 cless wbglbnr-0">
                      <div className="imgbanner">
                        <div className="img-banner firstban">
                          <div className="beffect">
                            <a href="#">
                              <img
                                className="img-fluid lazyload"
                                data-sizes="auto"
                                id="imgas"
                                src={image2}
                                data-src="/product_images/uploaded_images/gallerybanner-1.jpg"
                                alt="banner"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7 col-12 wbglbnr-0 mt-5 pt-2">
                      <div className="vidhover">
                        <h4>The Art Of Armour Making</h4>
                        <span
                          className="play-video"
                          data-toggle="modal"
                          data-target="#vedioModal"
                        >
                          <i className="fa fa-play" />
                          <p className="m-2">play video</p>
                        </span>
                        <div
                          id="vedioModal"
                          className="modal fade"
                          role="dialog"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                              >
                                ×
                              </button>
                              <div className="modal-body">
                                <iframe
                                  src="https://player.vimeo.com/video/361375942"
                                  webkitallowfullscreen="webkitallowfullscreen"
                                  mozallowfullscreen="mozallowfullscreen"
                                  allowFullScreen="allowfullscreen"
                                  width="100%"
                                  height={360}
                                  frameBorder={0}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 cless wbglbnr-1">
                      <div className="imgbanner">
                        <div className="img-banner firstban">
                          <div className="beffect">
                            <a href="#">
                              <img
                                className="img-fluid lazyload"
                                data-sizes="auto"
                                src={image1}
                                id="imgas"
                                alt="banner"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">05</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#Govts">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ background: "#f5f5f5" }} id="Govts">
                <section className="container">
                  <div className="row mb-4">
                    <div className="col-md-12 pb-2 pt-2">
                      <div className="heading text-center">
                        <h2 className="pb-2">
                          <a className="textbot">
                            {" "}
                            Where Art is taken beyond boundaries
                          </a>{" "}
                          <small style={{ fontWeight: "bold" }}>
                            The Art of Avakaya
                          </small>
                        </h2>
                      </div>
                      <p className="opl pt-3 text-center">
                        The pickle-making process, like visual art, involves a
                        meticulous blend of ingredients and techniques,
                        reflecting cultural heritage and craftsmanship. At
                        Indfame, we believe art is a lifestyle, celebrating the
                        rich traditions and communal rituals that infuse
                        everyday practices with creativity and meaning.
                      </p>{" "}
                    </div>

                    <div className="col-md-6">
                      <div className="text-center ">
                        <img
                          src={Av1}
                          style={{
                            height: "430px",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-center ">
                        <img
                          src={Av2}
                          style={{ height: "430px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">06</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#Govt">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div
                id="Govt"
                className="videobann section-space"
                style={{ background: "#ffffff" }}
              >
                <div className="container">
                  <div className="heading text-center">
                    <h2>
                      {" "}
                      <a className="textbot">{govtt.name}</a>
                    </h2>
                  </div>
                  <div className="row mt-5 mb-4">
                    <div className="col-sm-12 col-12 cless wbglbnr-0">
                      <div className="imgbanner">
                        <div className="img-banner firstban text-center">
                          <img
                            style={{ width: "150px" }}
                            className="img-fluid lazyload"
                            data-sizes="auto"
                            src={imgUrl + govtt.image}
                            alt="banner"
                          />
                          <br />
                          <span
                            style={{ fontSize: "16px" }}
                            className="text-muted"
                          >
                            Supported By
                          </span>
                          <br />
                          <span style={{ fontSize: "18px" }}>
                            <b>{govtt.description}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">07</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#Schools">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="Schools"
                style={{ background: "#f5f5f5" }}
                className="videobann section-space"
              >
                <div className="container">
                  <div className="heading text-center">
                    <h2>
                      {" "}
                      <a className="textbot">{titles.schools}</a>
                    </h2>
                  </div>
                  <div className="mt-5 mb-4">
                    <Slider {...settings2}>
                      {schools.map((data, key) => (
                        <div key={key} className="p-3">
                          <img
                            src={imgUrl + data.image}
                            className="rounded border border-secondary"
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">08</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center mt-4">
                        <a href="#Testimonial">
                          <b>Next</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Testimonial"
                className="videobann section-space"
                style={{ background: "#ffffff" }}
              >
                <div className="container">
                  <div className="heading text-center mt-3 ">
                    <h2>
                      {" "}
                      <a className="textbot mb-5">{titles.testimonial}</a>
                    </h2>
                  </div>
                  <div className="">
                    <Slider {...settings1}>
                      {testimonials.map((data, key) => (
                        <div
                          key={key}
                          className="card rounded cardshadow text-center"
                        >
                          <div
                            style={{ background: "#fff" }}
                            className="p-3 m-3 carheighttest"
                          >
                            <div className="d-flex justify-content-center">
                              <img
                                src={imgUrl + data.image}
                                className="rounded-circle border border-warning"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                            <span>
                              <b>{data.name}</b>
                            </span>

                            <div>
                              {data.rating == "1" ? (
                                <i
                                  class="fa fa-star text-warning m-1"
                                  aria-hidden="true"
                                ></i>
                              ) : data.rating == "2" ? (
                                <>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : data.rating == "3" ? (
                                <>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : data.rating == "4" ? (
                                <>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="fa fa-star text-warning m-1"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              )}
                            </div>
                            <span>{data.description.slice(0, 400)}</span>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4">
                      <div className="pagination">
                        <div>
                          <a className="current">09</a>
                        </div>
                        <div className="total">09</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};
export default Home;
